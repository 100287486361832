.insights-container{
    margin-bottom: -20px;
    /*margin-right: 70px;*/
    margin-top: 60px;
    min-width: 95% !important;
}

.row-container{
}

.col-insight{

}

.color-green{
    color: green;
}

.color-red{
    color: #f75452;
}