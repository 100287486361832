.repos-number{
    margin-left: 5px;
}

.bar-chart{
    max-height: 500px;
    margin-bottom: 70px;
}

.repo-chart-container{
    margin-left: 10px !important;
    margin-bottom: 10px;
    /*max-height: 400px;*/
}