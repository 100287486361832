#single-project{
    margin-left: 30px;
    margin-right: 70px;
    margin-top: -50px;
}

.project-title{
    margin-top: 50px;
    margin-left: 45%;
    margin-bottom: -50px;
    position: absolute;
    text-align: center;
}

.title-text{
    font-family: Arial, sans-serif;
    /*font-style: oblique;*/
    font-size: 1.2em;
}

.title-text-2{
    font-family: Arial, sans-serif;
    font-style: oblique;
    font-size: 1em;
    margin-left: 7%;
}

.project-description{
    margin-top: 100px;
    /*margin-bottom: -50px;*/
}

.project-summary{
    margin-top: 30px;
    margin-bottom: 30px;
}

.project-summary-table{
    border: 1px solid;
    border-color: ghostwhite;
    /*width: 100%;*/
    border-collapse: collapse;
    text-align: center !important;
}
.project-summary-table2{
    /*width: 100%;*/
    border: 2px solid;
    border-color: ghostwhite;
    text-align: center !important;
}

.project-logo{
    margin-right: 5px;
    margin-bottom: 3px;
    margin-left: 20px;
}
.project-desc{
    margin-left: 10px;
    margin-top: 10px;
}
.header-project-details{
    margin-top: 50px;
    margin-bottom: -30px;
    font-size: 1em;
}

.dev-report{
    margin-top: 20px;
    font-size: 1em;
    margin-bottom: -20px;
}

.blue-back{
    background-color:   #e6f2ff;
}

.purple-back{
    background-color:   #e6e6ff;
}

.header-back{
    background-color:    #e6ecff;
}

@media screen and (max-width: 500px) {
    #single-project{
        margin-left: -10px;
        margin-right: -5px;
    }
    .project-title{
        margin-left: 10%;
    }
    .project-summary-container{
        display: none;
    }
    .header-project-details{
        margin-left: 10px;
    }
    .chart-area{
        margin-left: 10px;
    }
    .top-repositories{
        margin-left: 1px !important;
        margin-right: -5px !important;
    }
}

