.matrix {
    /*display: flex;*/
    margin-top: 50px;
    /*display: grid;*/
}

.matrix-item {
    flex: 1;
    border: 1px solid grey;
    border-radius: 5px;
    margin: 1px;
    width: 30px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
}


.tooltip-card {
    border-radius: 5px;
    position: absolute;
    padding: 10px;
    border: 1px solid grey;
    display: none;
}

.green-tooltip {
    background: linear-gradient(to bottom, lightgreen, darkgreen);
}

.red-tooltip {
    background: linear-gradient(to bottom, lightcoral, red);
}

.green-tooltip-light {
    background: linear-gradient(to bottom, lightgoldenrodyellow, lightgreen);
}

.red-tooltip-light {
    background: linear-gradient(to bottom, lightpink, lightcoral);
}

.matrix-item:hover .tooltip-card {
    display: block;
}

.border {
    border: 1px solid red;
}

