.tokenomics-item{
    /*max-height: 200px;*/
    /*height: 300px;*/
    margin-bottom: 100px;
}

.token-name{
    font-size: 1.2rem;
    font-family: "Poppins", sans-serif;
}

.title-tokenomics{
    margin-bottom: 100px !important;
}