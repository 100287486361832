.overflow-hidden {
    overflow: hidden !important;
}
.table-responsive {
    overflow: hidden !important;
}

#sourceRepo{
    margin-right: 100px;
    /*color: rgb(29,140,248);*/
    color: violet;

}