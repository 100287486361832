.landingPage{
    width: 500px;
    height: 50px;
    position: absolute;
    left: 55%;
    z-index: 1;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

#commitsContainer{
    margin-top: 10px;
    margin-left: 2px;
}

.thumbnails{
    margin-top: 60px;
}

.articles{
    margin-top: 60px;
}

@media screen and (max-width: 500px) {
    .commits-container {
        /*width: 99%;*/
    }
    .card-container {
       width: 97%;
    }
    .insights-container{
        width: 95%;
    }
    #info{
       margin-right: 10px !important;
    }
    .centered-buttons{
        left: 30%;
    }
}