.large-insights-container{
    /*width: 1300px;*/
    width: 95%;
    margin-top: 50px;
}

#insight-header{
    margin-bottom: 20px;
}

.table-back{
    background-color:    #e6eefa;
}

.project-summary-table-name{
    border: 2px solid;
    border-color: ghostwhite;
    text-align: left !important;
    cursor: pointer;
    padding-left: 100px;
    width: 400px;
}

@media screen and (max-width: 500px) {
    .project-summary-table-name{
        width: 250px;
        padding-left: 10px;
    }
    .large-insights-container{
        margin-left: 10px;
        width: 95%;
    }
    .type{
        font-size: 0.7rem;
    }

}

.project-summary-table-name:hover {
    color: cornflowerblue;
}


.blue-back0{
    background-color:    #cae9fa;
}

.blue-back1{
    background-color: #d9effc;
}

.blue-back2{
    background-color:   #e6f2ff;
}

.blue-back3{
    background-color:    #e6f9ff;
}

.purple-back1{
    background-color:     #f2e6ff;
}

.purple-back2{
    background-color:      #ffe6ff;
}

.purple-back3{
    background-color:      #f2e6ff;
}
