.autocomplete-input-controls{
    margin-left: 30px;
}

.autocomplete-input {
    margin-left: 30px;
    border:none;
    border-bottom: 1px solid #1890ff;
    /*box-shadow: 1px 1px 1px 1px aliceblue;*/
    /*border-radius: 10px;*/
    text-indent: 10px;
    font-family: Amiri, Georgia, serif !important;
}

.no-suggestions {
    color: #999;
    padding: 0.55rem;
}
.left{
    /*margin-left: -20px !important;*/
}
.suggestions {
    /*border: 1px solid #999;*/
    /*border-top-width: 0;*/
    margin-left: 30px;
    list-style: none;
    margin-top: 0;
    max-height: 130px;
    overflow-y: auto;
    padding-left: 0;
    width: 350px !important;
}

.suggestions li {
    padding: 0.4rem;
    text-indent: 50px;
    /*border-radius: 10px;*/
}

.suggestion-active,
.suggestions li:hover {
    background-color: #2ca8ff;
    color: white;
    cursor: pointer;
    font-weight: 700;
    border-radius: 10px;
}
.neighbour-row{
    margin-left: 50px;
}
/*.suggestions li:not(:last-of-type) {*/
/*    border-bottom: 1px solid #999;*/
/*}*/
