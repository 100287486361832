.content-center{
    margin-top: 20px;
    position: absolute;
    top: 50%;
    left: 55%;
    z-index: 2;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.center{
    margin-top: 50px;
    left: 45%;
    position: absolute;
}

.title{
    margin-top: -25px;
    /*margin-left: -10px;*/
    margin-bottom: 50px !important;
    font-size: 0.9rem;
    color: #525f7f !important;
    min-width: 200px;
    line-height: 1.2;
}

.title-sub{
    font-size: 0.7rem;
    /*position: absolute;*/
    /*left: 42%;*/
    margin-top: 50px;
    margin-bottom: -30px;
    /*margin-top: 30px;*/
}
.multi-chart{
    margin-top: 50px !important;
}

.lastupdate{
    font-family: "Poppins", sans-serif;
    font-size: 0.7rem;
    font-style: italic;
    margin-top: 0px;
    width: 300px;
}

.subtitle{
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    margin-top: 90px;
    /*margin-bottom: -60px;*/
    /*left: 50%;*/
    /*-ms-transform: translate(-50%, -50%);*/
    /*-webkit-transform: translate(-50%, -50%);*/
    /*transform: translate(-50%, -50%);*/
    /*text-align: center;*/
}

.centered-buttons{
    margin-top: 30px;
    left: 40%;
    position: absolute;
}

@media screen and (min-width: 500px) {
    .buttons-frame {
        top: 130px !important;
    }
}


@media screen and (max-width: 500px) {
    .title {
        margin-top: -10px;
        margin-left: -10px;
        margin-bottom: 50px !important;
    }
    .lastupdate{
        margin-left: -10px;
    }
    .center{
        margin-top: 80px;
    }
    .content-center{
        left: 52% !important;
    }
    .multi-chart{
        margin-top: 100px !important;
    }
    .buttons-frame{
        margin-top: 150px;
    }
    .btn-group-toggle{
        margin-left: -20px !important;
    }
}