.crypto-container {
    margin: 50px;
    margin-right: 100px;
}

.crypto-desc{
    margin: 10px;
}

.first{
    margin-bottom: 20px;
}
