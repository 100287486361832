
#info{
    margin-top: 10px;
    margin-right: 70px;
    margin-bottom: -60px;
}
.info-view{
    margin: 20px;
    font-family: Arial, sans-serif;
    font-size: 0.9rem;
    text-align: left;
}

.info-text{
    font-family: Arial, sans-serif;
    font-size: 0.9rem;
    font-weight: bold;
}

.info-img{
    margin-top: 20px;
    margin-left: 20px;
}

.underline{
    text-decoration: underline;
}

.italic{
    font-style: italic;
}

.details-button{
    margin-top: 5px;
    cursor: pointer;
    color: cornflowerblue;
    font-style: italic;
}
@media screen and (max-width: 500px) {
    #info {
      margin-right: 10px;
        margin-left: 10px;
    }
}