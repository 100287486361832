.input-with-hint {
  display: flex;
  /*justify-content: center;*/
  text-indent: 20px;

  font-family: Arial, sans-serif;
  font-style: oblique;
  font-size: 1em;
  background: rgba( 255, 255, 255, .5);
  font-weight: bold;
  /*-webkit-box-shadow: 1px 1px 1px rgba(132,178,170, 0.75);*/
  /*-moz-box-shadow: 1px 1px 1px rgba(132,178,170, 0.75);*/
  /*box-shadow: 1px 1px 1px rgba(132,178,170, 0.75);*/
}

.input-with-hint:focus {
  background: rgba( 255, 255, 255, .2);
  border-color: white;
  outline: 0;
}

.rah-hint{
  font-style: oblique;
  display: flex;
}

.hidden{
  display: none !important;
}


.site-logo-img{
  width: 220px;
  margin-left: -20px;
  margin-bottom: 30px;
  filter: drop-shadow(2px 2px 2px dodgerblue) grayscale(10%);
}

@media screen and (max-width: 500px) {
  .site-logo-img {
    margin-left: 30px;
    width: 150px;
    margin-bottom: 5px;
  }
  /*.navbar-wrapper{*/
  /*  display: none !important;*/
  /*}*/
  .navbar-toggler-bar {
    /*display: none !important;*/
  }
  .navbar-top{
    /*display: none !important;*/
  }
  .navbar-toggler{
    /*display: none !important;*/
  }
}


.sidebar[data="blue"] {
  background: #3358f4;
  background: -webkit-linear-gradient(0deg,  #1d8cf8 0%,  #52a6fa 100%) !important;
  background: -o-linear-gradient(0deg, #1d8cf8 0%, #52a6fa 100%) !important;
  background: -moz-linear-gradient(0deg, #1d8cf8 0%, #52a6fa 100%)!important;
  background: linear-gradient(0deg, #1d8cf8 0%, #52a6fa 100%)!important;
  filter: grayscale(15%);
  /*filter: drop-shadow(1px 1px 1px dodgerblue) grayscale(15%);*/
}

.sidebar{
  /*filter: drop-shadow(1px 1px 1px dodgerblue) grayscale(15%);*/
  filter: grayscale(15%);
}

.navbar-top{
  /*filter: drop-shadow(1px 1px 1px lightslategray) grayscale(25%);*/
  filter: grayscale(25%);
  /*background-color: #1f8ef1 !important;*/

}

#light-nav{
  cursor: pointer;
  margin-top: 8px;
  margin-bottom: 5px;
}

.light-img{
  width: 30px !important;
}

.compare-img:hover{
  transform: scale(1.2);
}

.new-feature{
  margin-left: -5px;
  margin-top: 5px;
  font-size: 1.3rem;
  color: dodgerblue;
  font-family: "Brush Script MT, Brush Script Std, cursive";
  font-weight: bold;
}