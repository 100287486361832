#shape-container {
    /*cursor: pointer;*/
    /*z-index: -1;*/
    filter: drop-shadow(2px 2px 2px lightsteelblue);
}

#shape-container:hover {
    cursor: pointer;
    filter: drop-shadow(5px 5px 5px dodgerblue) grayscale(60%);
    /*zoom: 2;*/
    /*z-index: 1;*/
}

.shadow {
    filter: drop-shadow(5px 5px 5px dodgerblue) grayscale(60%);
}