.compare-container{
    margin-top: 70px;
}

.multi-chart{
    margin-left: 20px !important;
    margin-top: 50px;
}

.compare-control{
    margin-bottom: 20px;
}

.post{
    margin-top: 50px;
    margin-left: 30px;
}

.compare-subtitle{
    text-align: center;
    margin-top: 50px;
    left: 5%;
    margin-left: 20px;
    margin-right: 20px;
}

.project-description-compare{
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 100px;
}

@media screen and (max-width: 500px) {
    .project-description-compare{
        margin-right: 25px !important;
    }
    .multi-chart{
        margin-top: 10px !important;
    }

    .compare-container{
        margin-top: 30px;
    }
}