.small-icon{
    width: 20px;
}

.right{
    padding-right: 10px;
    padding-left: 10px;
}
.right-icon{
   margin-right: 7px;
}

.right-small{
    padding-left: 10px;
}

.right-nano{
    padding-left: 10px;
    cursor: pointer;
    color: mediumpurple;
}

.index{
    color: darkgrey;
}


.table-align{
    text-align: center !important;
}

.insights-card{
    /*margin-left: 10px;*/
}

.insights-table{
    /*border-collapse: collapse;*/
    /*width: 90%;*/
    margin-top: 15px;
    margin-left: 10px;
}

.insight-grid-title{
    margin-top: 10px;
    margin-left: 20px;
    margin-bottom: -5px;
    font-family: Arial, sans-serif;
    font-size: 1em;
    color: darkgrey !important;
}

.edge-col{
    border-right: 1px solid whitesmoke;
}

.project-column{
    min-width: 110px;
}

tr.tr-insights>td {
    padding-bottom: 0.5em;
    padding-top: 0.5em;
}

.small-grid-details-link{
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

.small-grid-details-link0{
    position: absolute;
    top: 5px;
    right: 30px;
    cursor: pointer;
}

.updating{
    margin-top: 5px;
}

.spinning-wheel{
    margin-left: 5px;
}
@media screen and (max-width: 500px) {
    .mobile-none {
      display: none;
    }
    .insights-container{
        margin-left: 10px;
    }
}