#projectsTableItem{
  cursor: pointer;
  color: rgba(255, 255, 255, 0.7) !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
}

#sidebar-element{
  color: rgba(255, 255, 255, 0.9) !important;
  /*color: white !important;*/
  border-color: rgba(255, 255, 255, 0.1) !important;
  cursor: pointer;
}

.sidebar-header{
  width: 100px;
}
.second-column{
  text-align: center;
}
#index-area{
  background-color:  #57abf4;
  /*background-color:   #e7f3fd;*/
  filter: drop-shadow(2px 2px 2px dodgerblue) grayscale(10%);
  border-radius: 5px;
  /*width: 23px;*/
  margin-right: 5px;
  height: 17px;
}

#index-p{
  font-size: 0.7rem !important;
  color: white;
  /*color: dodgerblue;*/
  margin-left: 5px;
}

#commits-area{
  background-color:    #cfe7fc;
  /*background-color:   #e7f3fd;*/
  /*background-color:  #57abf4;*/
  filter: drop-shadow(2px 2px 2px dodgerblue) grayscale(10%);
  border-radius: 5px;
  width: 50px;
  margin-right: 5px;
  height: 20px;
  cursor: pointer;
}

#commits-p{
  font-size: 0.85rem !important;
  color: dodgerblue;
  /*color: white;*/
  margin-left: 5px;
}



.first-th{
  /*width: 5%;*/
  border-color: rgba(255, 255, 255, 0.1) !important;
}

#td-name-element-1{
  margin-left: -20px;
  font-size: 0.75rem !important;
  font-weight: bold;
  font-family: "Poppins", sans-serif !important;
  /*color: white;*/
  color: rgba(255, 255, 255, 0.8) !important;
}

#td-name-element-2{
  margin-left: -20px;
  font-size: 0.75rem !important;
  font-family: "Poppins", sans-serif !important;
  /*color: white;*/
  color: rgba(255, 255, 255, 0.8) !important;
}

.logo-table{
  filter: drop-shadow(2px 2px 2px dodgerblue) grayscale(10%);
  /*margin-right: -10px;*/
  margin-left: -3px;
}