.project-container{
  margin-top: 50px;
}

@media screen and (max-width: 1000px) {
  .project-container {
    margin-left: 5px !important;
  }
}

.timespan-buttons{
  margin-top: 15px;
  position: absolute;
  left: 50%;
  z-index: 2;
}

#total-commits{
  cursor: pointer;
}

.link{
  margin-left: 20px;
}
