.table-container {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}

.priceTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.thPrice, .tdPrice {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.text-center {
  text-align: center;
}

