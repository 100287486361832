.label {
    margin-top: -22px;
    margin-left: 5px;
    height: 23px !important;
    display: inline-block;
    line-height: 1;
    vertical-align: baseline;
    background-color: #e8e8e8;
    background-image: none;
    padding: 0.5833em 0.833em;
    color: rgba(0,0,0,.6);
    text-transform: none;
    font-weight: 700;
    border: 0 solid transparent;
    border-radius: 0.28571429rem;
    transition: background .1s ease;
    cursor: pointer;
}

.image {
    margin-top: -10px;
    margin-right: 5px;
    /*padding-right: 10px;*/
    width: 20px;
    height: 18px !important;
    cursor: pointer;
}

.image:hover + .innerText {
    display: block;
    background-color: #0a0c0d;
}

.innerText {
    display: none;
}

#languageContainer{
    margin-left: 30px;
}


@media screen and (max-width: 500px) {
  .label-language{
      margin-bottom: 7px;
  }
}


