.logo-art{
    fill: red;
    margin-bottom: 50px;
}

.art-container{
    margin-top: 40px;
}

.art-author{
    margin-top: 30px;
    /*width: 200px;*/
}

.posted-by{
    margin-left: -40px;
    margin-top: 4px;
}

.art-title{
    margin-top: 20px;
    margin-left: 30px;
    /*margin-left: 300px;*/
    font-size: 25px;
    font-weight: bold;
}